/* eslint-disable security/detect-object-injection */
import { createStore, applyMiddleware, Middleware, Store, Reducer } from 'redux'
import thunk from 'redux-thunk'
import { persistStore } from 'redux-persist'
import segmentTracking from '../segment/middlewares/segment'
import webLinkCart from '@bees-web/web-interactive-link-cart/middlewares/index'
import { composeWithDevTools } from '@redux-devtools/extension'
import { createWrapper, MakeStore } from 'next-redux-wrapper'
import { combineContextualReducers, configureReducer } from './reducers'
import { ReduxContext } from '@bees-web/nfa-types'
import { setSharedContext } from '../utils/VL/context/sharedContext'

// theses reducers persist are configured separately in ./reducers
const middleware: Middleware[] = [thunk, segmentTracking, webLinkCart()]
const version = parseInt(process.env.BUILD_BUILDID)

export type LoadContextualRedux = (
  store: Store,
  modules: Record<string, ReduxContext>
) => void

export const configure = configureReducer(version)

export const loadContextualRedux: LoadContextualRedux = (store, modules) => {
  setSharedContext(modules)
  const loadedReducers: Record<string, Reducer> = Object.keys(modules).reduce(
    (prev, curr) => ({ ...prev, [curr]: modules[curr].state.reducer }),
    {}
  )
  store.replaceReducer(combineContextualReducers(version, loadedReducers))
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //@ts-ignore no type available for persistStore
  const persistor = persistStore(store, { manualPersist: false })
  persistor.persist()
}

export const makeStore: MakeStore<Store> = () =>
  createStore(
    combineContextualReducers(version, {}),
    {},
    composeWithDevTools({
      actionsDenylist: [
        'RENDER_DEFERRED_FROM_CACHE',
        'JOKER_DEFERRED_REQUEST_MADE',
        'JOKER_DEFERRED_RESPONSE_SAVED',
      ],
    })(applyMiddleware(...middleware))
  )

// export an assembled wrapper
const wrapper = createWrapper<Store>(makeStore, {
  debug: false,
  serializeState: (state) => JSON.stringify(state), // Added to omit 'undefined' attribute values
  deserializeState: (state) => {
    try {
      return JSON.parse(state)
    } catch (e) {
      return state
    }
  },
})

export default wrapper
