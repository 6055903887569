export const linkRoutes = (id: string) => ({
  [`/account/invoices/details/${id}`]: 'post-sales_invoices_details',
  [`/invoices/invoice/detail/id/${id}`]: 'post-sales_invoices_details',
  [`/sales/order/cancel/order_id/${id}`]: 'post-sales_order_cancellation',
  [`/sales/order/view/order_id/${id}`]: 'post-sales_order_details',
  [`/sales/order_cancel/confirmed/order_id/${id}`]:
    'post-sales_order_cancellation_success',
  [`/category/${id}`]: 'products_plp',
  [`/product/${id}`]: 'products_pdp',
  [`/combos/${id}`]: 'deals_pdp-combos',
  [`/combos/regular/${id}`]: 'deals_pdp-regular-combos',
  [`/digital-experience/${id}`]: 'home_digital-experience',
  [`/campaign/${id}`]: 'web-link-campaigns_campaign',
  '/customer/account': 'account_info',
  '/purchase-order-files': 'account_purchase_order_files',
  '/globalrecommendation/entire/order': 'algo-selling_view_entire_order',
  '/account/invoices': 'post-sales_invoices',
  '/invoices': 'post-sales_invoices',
  '/faqs': 'home_faq',
  '/cms/index/home': 'web-link-home_signed_homepage',
  '/ratings/view': 'web-link-home_signed_homepage',
  '/support/page/tickets': 'customer-experience_main',
  '/support/page/tickets/requests': 'customer-experience_main',
  '/support/page/tickets/new-request': 'customer-experience_main',
  '/support/page/tickets/new-ticket': 'customer-experience_main',
  '/support/page/tickets/order-selection': 'customer-experience_main',
  '/support': 'customer-experience_main',
  '/support/requests/new': 'customer-experience_main',
  '/support/requests/new-ticket': 'customer-experience_main',
  '/support/requests/order-selection': 'customer-experience_main',
  '/support/requests/history': 'customer-experience_main',
  '/support/requests/view': 'customer-experience_main',
  '/support/contact': 'customer-experience_main',
  '/sales/order/history': 'post-sales_my_orders',
  '/sales/order/react/history': 'account_react_my_orders',
  '/401': 'global_error_401',
  '/not_found': 'global_error_404',
  '/checkout': 'checkout_main',
  '/checkout/cart': 'cart_main',
  '/authentication/account/error': 'global_auth_error',
  '/checkout/success': 'checkout_success',
  '/discounts': 'deals_discounts',
  '/catalogsearch/result': 'products_plp',
  '/privacy-policy': 'home_privacy_policy',
  '/terms-and-conditions': 'home_terms_and_conditions',
  '/personal-information-collection': 'home_personal_information_collection',
  '/accessibility-statement': 'home_accessibility_statement',
  '/digital-services': 'digital-services_services_main',
  '/customer/account/language': 'account_language',
  '/customer/account/delete': 'account_delete',
  '/customer/account/access': 'account_access',
  '/customer/account/access/error': 'account_access_error',
  '/customer/poc/register': 'account_register',
  '/customer/poc/register/success': 'account_success',
  '/customer/poc/register/success-3p': 'account_success_3p',
  '/customer/poc/update': 'account_profile_update',
  '/customer/smart-onboarding/pre-onboarding': 'account_pre_onboarding',
  '/customer/smart-onboarding/vendor-select': 'account_vendor_select',
  '/customer/smart-onboarding/validation': 'account_validation',
  '/credit': 'payments_credit',
  '/parlist': 'algo-selling_parlist',
  '/advanced-date': 'products_advanced-date',
  '/customer/notification-preferences': 'account_notification_preferences',
  '/customer/account-receivables': 'account_account_receivables',
  '/experimental/core/stores': 'global_experimental_core_stores_page',
  '/marketing-use-agreement': 'home_marketing_use_agreement',
  '/eu/volume-report': 'eu-finance_volume_report_page',
  '/eu/bonus': 'eu-finance_bonus_page',
  '/eu/welcome': 'eu-finance_welcome_page',
  '/eu/contract-registration': 'eu-finance_contract_registration_page',
  '/eu/volume-report-mobile': 'eu-finance_volume_report_page_mobile',
  '/eu/bonus-mobile': 'eu-finance_bonus_page_mobile',
  '/eu/welcome-mobile': 'eu-finance_welcome_page_mobile',
  '/eu/contract-registration-mobile':
    'eu-finance_contract_registration_page_mobile',
  '/link/home': 'web-link-home_signed_homepage',
  '/link/upload_order': 'link_upload_order',
  '/link/curation': 'link_curation',
  '/link/order_tracking': 'link_order_tracking',
  '/link/finances': 'finances_home',
  '/product-order': 'product-order_main',
  '/comm-agreements': 'link-insights_comm-agreements',
})
