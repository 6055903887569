/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import { storage } from '../utils/createWebStorage'
import globals, {
  blackList as blacklist,
} from '@bees-web/nfa-interactive-global/reducers'
import { Reducer } from 'redux'
import { clientServerReconcilier } from '../utils/VL'
import { ConfigureReducer } from '../utils/VL/context/getReduxContextMap'

// Use to configure from ValueStreamConfiguration
export const configureReducer =
  (version: number): ConfigureReducer =>
  (configuration, redux) => {
    const { reducer, persist, serverReconciler } = redux.state

    const ssrReducer = clientServerReconcilier(
      reducer,
      (payload) => payload[configuration.namespace],
      serverReconciler
    )

    return persist
      ? persistReducer(
          {
            storage,
            ...persist(version),
          },
          ssrReducer
        )
      : ssrReducer
  }

const getGlobalState = (version: number) =>
  persistReducer(
    {
      key: `new-web-react-globals-${version}`,
      storage,
      blacklist,
      version,
    },
    clientServerReconcilier(
      globals,
      (payload) => payload.globals,
      (state, payload) => ({
        ...state,
        cache: {
          ...payload.cache,
          pages: {
            ...payload.cache.pages,
            ...state.cache.pages,
          },
          deferred: state.cache.deferred,
          selectedPocAccount: state.cache.selectedPocAccount,
          storeId: state.cache.storeId,
          activeCacheKey: state.cache.activeCacheKey,
          meta: {
            ...payload.cache.meta,
            ...state.cache.meta,
          },
        },
        user: {
          ...state.user,
          ...payload.user,
        },
      })
    ) // Apply client-server reconcilier
  )

export type CombineContextualReducers = (
  version: number,
  reducers: Record<string, Reducer>
) => Reducer

export const combineContextualReducers: CombineContextualReducers = (
  version,
  reducers
) =>
  combineReducers({
    globals: getGlobalState(version),
    ...reducers,
  })
