/* eslint-disable security/detect-object-injection */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useState, useEffect, useMemo, useCallback } from 'react'
import {
  DynamicVSConfiguration,
  IApplicationState,
  SimpleVSConfiguration,
} from '@bees-web/nfa-types'
import { UseInteractive } from '../../../interfaces'
import { useStore } from 'react-redux'
import loadReduxContext from './loadReduxContext'
import { loadComponentPack } from '../getComponent'
import { dependencies } from '../../../config'

export type UseValueStreamProps = {
  interactiveMap: UseInteractive
  isLoading: boolean
  stateSelector: (state: IApplicationState) => IApplicationState
}

const combineInteractives =
  (interactives: UseInteractive[] = []): UseInteractive =>
  (dispatch, state, globalActions) => {
    return interactives.reduce(
      (prev, curr) => ({
        ...prev,
        ...(typeof curr === 'function'
          ? curr(dispatch, state, globalActions)
          : {}),
      }),
      {}
    )
  }

const useValueStream = (
  namespace: string,
  pageDependencies?: string[]
): UseValueStreamProps => {
  const store = useStore()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [interactiveMap, setInteractiveMap] = useState<UseInteractive | null>(
    null
  )

  const moduleDependencies = useMemo(() => {
    const vs = dependencies[namespace]
    if (vs) {
      const vsDependencies =
        (vs as DynamicVSConfiguration).dependencies ||
        (vs as SimpleVSConfiguration).state?.dependencies

      return [...(pageDependencies || vsDependencies || []), namespace]
    }
    return null
  }, [namespace, pageDependencies])

  const importDependencies = useCallback(async (): Promise<void> => {
    if (moduleDependencies) {
      try {
        const [componentsLoaded, modules] = await Promise.all([
          loadComponentPack(),
          loadReduxContext(store, moduleDependencies),
        ])
        if (componentsLoaded && modules) {
          setInteractiveMap(() =>
            combineInteractives(
              Object.values(modules).map(
                (module) => module.actions?.interactiveMap
              )
            )
          )
          setIsLoading(false)
        }
      } catch (error) {
        console.error(
          `Error loading modules [${moduleDependencies.join(',')}]: ${
            error.message
          }`
        )
      }
    }
    setIsLoading(false)
  }, [store, moduleDependencies])

  useEffect(() => {
    importDependencies()
  }, [importDependencies])

  return {
    interactiveMap,
    isLoading,
    stateSelector: (state: IApplicationState) => ({
      state: moduleDependencies
        ? moduleDependencies.reduce(
            (prev, curr) => ({ ...prev, ...state[curr] }),
            {}
          )
        : state,
    }),
  }
}

export default useValueStream
