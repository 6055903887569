/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable security/detect-object-injection */
import React, { createElement } from 'react'
import { useSelector, useStore } from 'react-redux'
import { DeferredRenderer } from '../components/deferredRenderer'
import { IRenderBlocks } from './interfaces'
import { IApplicationState } from '@bees-web/nfa-types'
import { mergeProperties as deepMerge } from '../utils/mergeProperties'
import { getComponent } from '../utils/VL'
import ErrorBoundary from '../components/error-boundary/errorBoundary'
import { CUSTOM_BLOCK_TAG } from '../utils/VL/getComponent'
import getSiteMap from '../core/routing/getSiteMap'

const sitemap = getSiteMap()

export const RenderBlocks = ({
  block,
  interactiveMap,
  pageTraceId,
}: IRenderBlocks) => {
  const store = useStore()
  const state = useSelector((state: IApplicationState) => ({
    cartQuantity: state.globals.cart.cartQuantity,
  }))

  const Block = getComponent(block)

  if (!Block) {
    console.info('Block not found:', block)
    return null
  }

  if (block.deferred) {
    const {
      deferred,
      waitForState = [],
      renderIf = '',
      ...loadingBlock
    } = block
    return (
      <DeferredRenderer
        parent={block}
        url={deferred}
        loadingBlock={loadingBlock}
        interactiveMap={interactiveMap}
        waitForState={waitForState}
        renderIf={renderIf}
        pageTraceId={pageTraceId}
      />
    )
  }

  block.meta = block.meta || {}

  if (block.attributes?.href) {
    const dynamicVariation =
      block.attributes.href.substring(
        0,
        block.attributes.href.lastIndexOf('/') + 0
      ) + '/[id]'
    block.meta.externalLink =
      block.attributes.href === '/' ||
      (!sitemap.routes[block.attributes.href] &&
        !sitemap.routes[dynamicVariation])
    if (sitemap?.dynamicRoutes?.[dynamicVariation]) {
      block.meta.dynamicRoute = dynamicVariation
    }
  }

  if (block.blockType === 'cart_link') {
    block.meta.cartQuantity = state.cartQuantity
  }

  const interactivity = block.interactiveId
    ? interactiveMap[block.interactiveId]
    : false
  const { attributes } = interactivity || {}
  const { mergeProperties = false } = block
  const { mergeProperties: mergeProps = mergeProperties } = attributes || {}
  const props = mergeProps
    ? deepMerge(block.attributes, attributes)
    : { ...block.attributes, ...attributes }
  const renderBlocksProps = {
    key: block.name,
    ...block,
    ...interactivity,
    ...block.attributes,
    ...props,
    attributes: props,
    store: block.blockType === CUSTOM_BLOCK_TAG ? store : {},
  }

  return createElement(
    Block,
    { ...renderBlocksProps },
    block.blocks?.map((block, idx) => (
      <ErrorBoundary
        blockName={block.name || ''}
        key={block.name || `b-${idx}`}
      >
        <RenderBlocks
          key={block.name || `b-${idx}`}
          block={block}
          interactiveMap={interactiveMap}
          pageTraceId={pageTraceId}
        />
      </ErrorBoundary>
    ))
  )
}
