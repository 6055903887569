export const logger = (level: string, params: any) => {
  switch (level) {
    case 'info':
      const infoParams = JSON.stringify({
        level,
        application: 'Render',
        ...params,
      })
      console.info(infoParams)
      break
    case 'error':
      const errorParams = JSON.stringify({
        level,
        application: 'Render',
        ...params,
      })
      console.error(errorParams)
      break

    case 'warn':
      const warnParams = JSON.stringify({
        level,
        application: 'Render',
        ...params,
      })
      console.warn(warnParams)
      break

    case 'log':
      const logParams = JSON.stringify({
        level,
        application: 'Render',
        ...params,
      })
      console.log(logParams)
      break
  }
}

export interface LoggerProps {
  env: string
  page: string
  message: string
  country: string
  language: string
  locale?: string
  ajs_user_id?: string
  ajs_anonymous_id?: string
  REDUXPOC?: string
  accountId?: string
  entityId?: string
  pageRoute?: string
  pathname?: string
  pageProps?: string
  pageName?: string
  allowTracker?: boolean
  enable_tracking?: string
  oneScriptTrustLoaded?: boolean
  version?: number | string
  iad?: string
  initialized?: boolean
  error?: string
}
