import React, { FC, ReactNode, useEffect } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { init } from '@bees-web/nfa-interactive-global/actions/authentication'
import { IApplicationState } from '@bees-web/nfa-types'
import { Store } from 'redux'

interface PageContainer {
  [keys: string]: unknown
  store: Store
  children: ReactNode
}

const AuthContainer: FC<PageContainer> = ({ children, ...props }) => {
  const dispatch = useDispatch()
  const { authentication: auth } = useSelector(
    (state: IApplicationState) => state.globals
  )

  useEffect(() => {
    if (!auth.isAuthenticated) {
      dispatch(init(false))
    }
  }, [auth.isAuthenticated])

  return (
    <div {...props} className="main">
      {children}
    </div>
  )
}

const PageContainer: FC<PageContainer> = ({ ...props }) => {
  const { store } = props

  // expose store when run in Cypress
  useEffect(() => {
    if (window['Cypress']) {
      window['store'] = store
    }
  })

  return (
    <Provider store={store}>
      <AuthContainer {...props} />
    </Provider>
  )
}

export default PageContainer
