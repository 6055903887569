import { IBlock, IPage, IReduxAction } from '@bees-web/nfa-types'

import {
  JOKER_DEFERRED_REQUEST_MADE,
  JOKER_DEFERRED_RESPONSE_SAVED,
  JOKER_DEFERRED_REQUEST_FAILED,
  RENDER_DEFERRED_FROM_CACHE,
  JOKER_RESPONSE_SAVED,
} from '@bees-web/nfa-interactive-global/constants'

export const requestDeferredFromJoker = (
  url: string,
  body?: object
): IReduxAction => ({
  type: JOKER_DEFERRED_REQUEST_MADE,
  payload: { url, body },
})

export const saveDeferredJokerResponse = (
  url: string,
  block: IBlock,
  body?: object
): IReduxAction => ({
  type: JOKER_DEFERRED_RESPONSE_SAVED,
  payload: { url, block, body },
})

export const failedDeferredJokerRequest = (
  url: string,
  error: any
): IReduxAction => ({
  type: JOKER_DEFERRED_REQUEST_FAILED,
  payload: { url, error },
})

export const renderDeferredFromCache = (url: string): IReduxAction => ({
  type: RENDER_DEFERRED_FROM_CACHE,
  payload: { url },
})

export const saveJokerResponse = (
  pageName: string,
  pageTemplate: IPage,
  pathname: string,
  body?: object
): IReduxAction => ({
  type: JOKER_RESPONSE_SAVED,
  payload: { pageName, pageTemplate, pathname, body },
})
