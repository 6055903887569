import { getCookie } from '../../utils/getCookie'

export const errorTemplate = (block) => [
  {
    attributes: { type: 'error', isDismissible: true },
    blocks: [
      {
        attributes: { title: `Error on block: ${block.name}` },
        blocks: [
          {
            blocks: [],
            blockType: 'Text',
            text: `Error: ${JSON.stringify(block.errors)}`,
          },
          {
            blocks: [],
            blockType: 'Text',
            text: ` | RequestTraceId: ${JSON.stringify(block.requestTraceId)}`,
          },
        ],
        blockType: 'Collapse',
      },
    ],
    blockType: 'Alert',
  },
]

export const handleDeferredError = (block) => {
  const isNonProd =
    process.env.STAGE?.toLocaleLowerCase() !== 'prod' &&
    process.env.STAGE?.toLocaleLowerCase() !== 'uat'
  const hasDebugCookie = getCookie('debug') === process.env.BUILD_BUILDID
  let isErrorPage = false

  if (isNonProd || hasDebugCookie) {
    return {
      content: {
        ...block,
        blocks: errorTemplate(block),
      },
    }
  }

  if (block.name.startsWith('global_error_503')) {
    isErrorPage = true
  }

  return {
    content: block,
    isPage: isErrorPage,
    pathname: window.location.pathname,
  }
}
