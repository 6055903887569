import { IBlock, IApplicationState } from '@bees-web/nfa-types'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { getHash } from '@bees-web/nfa-interactive-global/utils/getHash'
import { renderDeferredFromCache } from '../../redux/actions/deferred'
import getFromJokerBlock from './getFromJokerBlock'

const loadDeferred = (
  url: string,
  parentBlock: IBlock,
  method?: string,
  body?: object,
  pageTraceId?: string
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => IApplicationState
  ): Promise<void> => {
    const {
      cache: {
        meta,
        activeCacheKey,
        activePageName,
        selectedPocAccount,
        storeId,
      },
    } = getState().globals

    const pageElements = meta[activeCacheKey] || {}
    const deferredCacheKey = `${url}&selectedPocAccount=${selectedPocAccount}&storeId=${storeId}${
      body ? `&body=${JSON.stringify(body)}` : ''
    }`

    const hash = getHash(deferredCacheKey)
    const { isValid = false, isLoading = false } = pageElements[hash] || {}

    // currently if the cache has been invalidated >
    // do not replace loading spinner with invalid block
    if (isValid || isLoading || !pageElements.isValid) {
      if (isValid) dispatch(renderDeferredFromCache(url))
      return
    }

    // it is invalid, but is it already loading?
    return dispatch(
      getFromJokerBlock({
        url,
        parentBlock,
        activePageName,
        method,
        body,
        pageTraceId,
      })
    )
  }
}

export default loadDeferred
