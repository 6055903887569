import { useEffect } from 'react'
import Cookies from 'js-cookie'

export const useLocale = (language, country) => {
  useEffect(() => {
    if (language && country) {
      Cookies.set('country', country, { path: '/' })
      Cookies.set('language', language, { path: '/' })
    }
  }, [language, country])
}
