import { isObject } from './isObject'
export type DeepMerge = (obj1: Object, obj2: Object) => Object
export const mergeProperties: DeepMerge = (a = {}, b = {}) =>
  Object.keys(a).reduce(
    (result, key) => {
      if (isObject(a[key]) && isObject(b[key])) {
        return { ...result, [key]: mergeProperties(a[key], b[key]) }
      }
      return { [key]: a[key], ...result }
    },
    { ...b }
  )
