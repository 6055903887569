export type GetHash = (string: string) => number

export const getHash: GetHash = (string) => {
  const hash = [...string].reduce(
    (hash, char) => ((hash << 5) - hash + char.charCodeAt(0)) << 0,
    0
  )

  return hash + 2147483647 + 1
}
