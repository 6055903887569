import { useEffect } from 'react'
import { loadDeferred } from '../blocks/deferred'
import { RenderingOptionProps } from '../interfaces'
import { IApplicationState } from '@bees-web/nfa-types'

export type IRenderingOptions = (
  props: RenderingOptionProps,
  dependencies: IApplicationState[]
) => any

export const renderingOptions: IRenderingOptions = (props, dependencies) => {
  const {
    filledOutUrl,
    waitForState,
    parent,
    method,
    body,
    dispatch,
    renderIf,
    pageTraceId,
  } = props

  useEffect(() => {
    const definedState = waitForState.find((state) =>
      filledOutUrl.includes(state)
    )

    if (waitForState.length && waitForState.includes(definedState)) {
      return
    }

    if (renderIf && !filledOutUrl.includes(renderIf)) {
      return
    }

    dispatch(loadDeferred(filledOutUrl, parent, method, body, pageTraceId))
  }, dependencies)
}

export default renderingOptions
