/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable security/detect-object-injection */
import { loadPage } from '@bees-web/nfa-interactive-global/actions/cache'
import Router from 'next/router'
import { logger, LoggerProps } from '../utils/logger'
import wrapper from '../redux'
import cookies from 'next-cookies'
import { Store } from 'redux'
import { NextPageContext } from 'next'
import uuidv4 from '@bees-web/nfa-interactive-global/utils/uuidGenerator'
import { applicationsRoutes } from '../core/routing/applications-routes'
import { PageProps } from '../core/interfaces'
import { getDeviceType } from '../core/tools/infos'
import getSiteMap from '../core/routing/getSiteMap'
import { getDomainLocale } from '../utils/VL'
import { updateUser } from '@bees-web/nfa-interactive-global/actions/user/updateUser'

const sitemap = getSiteMap()

const routeToPageNames = (
  id: string,
  application = 'CUSTOMER'
): { [key: string]: string } => applicationsRoutes[application](id)

export const buildGetInitialProps =
  (store: Store) =>
  async (context: NextPageContext): Promise<PageProps> => {
    const { query: contextQuery, res } = context
    const { ajs_user_id, ajs_anonymous_id, enable_tracking, APPLICATION } =
      cookies(context)
    const safeAjsUserId = ajs_user_id?.replace(/[^a-z0-9-_]/gi, '') || null
    const isServer = !!context.req

    const device = getDeviceType(
      context?.req?.headers?.['user-agent'] || window?.navigator?.userAgent
    )

    const id = contextQuery?.id ? contextQuery?.id.toString() : ''
    const { globals } = store.getState()
    const routeToPageName = routeToPageNames(id, APPLICATION)
    const pathname = context.asPath.split('?')[0]

    try {
      const { isSupported, country, language, locale } = getDomainLocale(
        isServer
          ? context
          : ({
              ...context,
              req: {
                headers: {
                  host: window?.location.host,
                },
              },
            } as NextPageContext)
      )

      if (isSupported) {
        store.dispatch(
          updateUser({
            country,
            language,
          })
        )

        // Remove query params and trailing slash to get the correct pageName
        const pathKey = pathname === '/' ? '/' : pathname.replace(/\/$/, '')
        const pageName = routeToPageName[pathKey] || pathname

        const { accountId } = globals.authentication

        const query = {
          pageName,
          locale,
          accountId,
          entityId: id,
          device,
          ...contextQuery,
        }

        if (res && !ajs_anonymous_id && enable_tracking === 'true') {
          res.setHeader('Set-Cookie', `ajs_anonymous_id=${uuidv4()}; Path=/;`)
        }

        const logMessageGetInitialParams: LoggerProps = {
          env: process.env.STAGE,
          page: 'withJoker.getInitialProps',
          message: `WithJoker => [pageName: ${pageName}], pathname:${pathname}, country:${country}, lang:${language}`,
          pageName,
          country,
          locale,
          accountId,
          entityId: id,
          pageRoute: pathname,
          language,
        }
        logger('info', logMessageGetInitialParams)

        await store.dispatch(
          loadPage({
            query,
            context,
            Router,
            sitemap,
            pageRoute: pathname,
          }) as any
        )

        return {
          isServer,
          isLocaleSupported: true,
          name: pageName,
          language,
          country,
          pathname: context.pathname,
          ajsUserId: safeAjsUserId,
          ajsAnonymousId: ajs_anonymous_id,
          locale,
          query,
          params: {},
          featureFlags:
            store.getState().globals.cache.pages[pageName]?.featureFlags || {},
        }
      }
    } catch (e) {
      const logMessageRenderWithJokerCatchParams: LoggerProps = {
        env: process.env.STAGE,
        page: 'withJoker.getInitialProps',
        message: `WithJoker => Error: ${e?.message}`,
        pageName: null,
        country: null,
        language: null,
        locale: null,
        error: e,
        pathname,
      }
      logger('error', logMessageRenderWithJokerCatchParams)
      console.error(e)
    }
    return undefined
  }

export type WithJoker = <T>(
  EnhancedComponent: T & { getInitialProps?: any },
  store?: Store
) => T & { getInitialProps?: any }

const withJoker: WithJoker = (EnhancedComponent, store) => {
  EnhancedComponent.getInitialProps = store
    ? buildGetInitialProps(store)
    : wrapper.getInitialPageProps(buildGetInitialProps)
  return EnhancedComponent
}

export default withJoker
