import { IBlock } from '@bees-web/nfa-types'
import { IFetchDeferredJoker } from '../interfaces'
import { fetcher } from '@bees-web/nfa-interactive-global/actions/utils/fetcher'
import { handleDeferredError } from './handleDeferredError'

//Replaces the old /bocks/?blockName=batata with /blocks/batata
function replaceBlockName(url: string) {
  const regex = /blockName=([^&]+)/
  const match = url?.match(regex)
  const blockName = match && match[1]
  return blockName ? url.replace('/blocks', `/blocks/${blockName}`) : url
}

export const fetchDeferredJoker = async (
  url: string,
  parentBlock: IBlock,
  method = 'GET',
  pageTraceId?: string,
  body?: object
): Promise<IFetchDeferredJoker> => {
  if (window.location.hostname === 'localhost') {
    url = `${process.env.JOKER_URL}${url}`
  }

  url = replaceBlockName(url)

  const isEmpty = (obj: any): boolean => {
    if (Array.isArray(obj)) {
      return obj.length === 0
    }

    if (typeof obj === 'object' && obj !== null) {
      return Object.keys(obj).length === 0
    }

    return false
  }

  const bodyStringified = body ? JSON.stringify(body) : null
  const defaultError = '&error_block_default=global_error_503'

  const headers = body
    ? {
        'Content-Type': 'application/json',
      }
    : {}

  headers['requestTraceId'] = pageTraceId

  //add blue green header to all calls to joker
  if (process.env.ENV_COLOR) {
    headers['activeCluster'] = process.env.ENV_COLOR
  }

  try {
    const res = await fetcher(`${url}${defaultError}`, {
      credentials: 'include',
      headers,
      method,
      body: bodyStringified,
    })

    if (res.status === 401) {
      const redirectPath = '/login'
      window.location.href = redirectPath
    }

    const block = await res.json()

    if (!isEmpty(block.errors)) {
      return handleDeferredError(block)
    }

    console.log({ message: 'Render: Finished fetching deferred block', url })

    return {
      content: block,
    }
  } catch (e) {
    console.log(`Error fetching deferred block, status: ${e}`)
  }
}

export default fetchDeferredJoker
