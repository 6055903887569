import { IBlock, IPage } from '@bees-web/nfa-types'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import {
  requestDeferredFromJoker,
  failedDeferredJokerRequest,
  saveDeferredJokerResponse,
  saveJokerResponse,
} from '../../redux/actions/deferred'
import { IGetFromJokerBlock } from '../interfaces'
import fetchDeferredJoker from './fetchDeferredJoker'

const getFromJokerBlock =
  ({
    url,
    parentBlock,
    activePageName,
    method,
    body,
    pageTraceId,
  }: IGetFromJokerBlock) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(requestDeferredFromJoker(url, body))
    try {
      const { content, isPage, pathname } = await fetchDeferredJoker(
        url,
        parentBlock,
        method,
        pageTraceId,
        body
      )

      if (isPage) {
        dispatch(
          saveJokerResponse(activePageName, content as IPage, pathname, body)
        )
      }

      dispatch(saveDeferredJokerResponse(url, content as IBlock, body))
    } catch (error) {
      dispatch(failedDeferredJokerRequest(url, error))
    }
  }

export default getFromJokerBlock
