/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/display-name */
import React, { useEffect } from 'react'
import Page from './page'
import { pageTracking } from '@bees-web/nfa-interactive-global/actions/page'
import { useDispatch } from 'react-redux'
import { useValueStream } from '../utils/VL'
import { ISimplePageProps } from '../interfaces'
import { getPageTracking } from '../core/segment'

export interface SimpleVSPageArgs extends ISimplePageProps {
  namespace: string
}

interface VSPageProps extends SimpleVSPageArgs {
  name: string
}

const VSPage = ({
  namespace,
  segmentPageName = null,
  scripts = [],
  globalNamespace,
  name,
}: VSPageProps) => {
  const { interactiveMap, isLoading, stateSelector } = useValueStream(namespace)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(pageTracking(getPageTracking(segmentPageName)))
  }, [dispatch, segmentPageName])

  return isLoading ? (
    <></>
  ) : (
    <Page
      stateSelector={stateSelector}
      useInteractive={interactiveMap}
      globalNamespace={globalNamespace}
    >
      {({ state, interactiveMap }) => (
        <Page.Render
          name={name}
          state={state}
          interactiveMap={interactiveMap}
          scripts={scripts}
        />
      )}
    </Page>
  )
}

const SimpleVSPage =
  (props: SimpleVSPageArgs) =>
  ({ name }: { name: string }) =>
    <VSPage {...props} name={name} />

export default SimpleVSPage
