/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/camelcase */

const getPageTracking =
  (segmentPageName) =>
  ({ name: payloadName, referrer, search_query, field_title, screen_name }) => {
    return {
      event: segmentPageName || payloadName,
      name: segmentPageName || payloadName,
      referrer,
      search_query: search_query || window.location.search || null,
      field_title: field_title || null,
      url: window.location.href,
      screen_name: screen_name || null,
    }
  }

export default getPageTracking
