/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable security/detect-object-injection */
import React, { ReactElement } from 'react'
import Head from 'next/head'
import { useSelector, useDispatch } from 'react-redux'
import { IApplicationState, IBlock } from '@bees-web/nfa-types'
import Script, { ScriptProps } from 'next/script'
import { getGlobalActions } from '../interactive'
import { RenderBlocks } from '../blocks'
import { IInteractiveMap } from '../blocks/interfaces'
import { interactiveGlobalMap } from '../interactive/interactive-global-map'
import { ErrorBoundary } from './error-boundary/errorBoundary'
import { UseInteractive } from '../interfaces'

export type TPageProps = {
  stateSelector: (state: IApplicationState) => IApplicationState
  useInteractive: UseInteractive
  children: (props: {
    state: IApplicationState
    interactiveMap: IInteractiveMap
  }) => ReactElement | null
  globalNamespace?: string
}

const Page = ({
  stateSelector,
  useInteractive,
  children,
  globalNamespace,
}: TPageProps): ReactElement | null => {
  const state = useSelector((applicationState: IApplicationState) => {
    if (stateSelector) {
      return {
        ...applicationState,
        ...stateSelector(applicationState),
      }
    }

    return applicationState
  })
  const dispatch = useDispatch()

  const interactiveMap = {
    ...useInteractive(dispatch, state, getGlobalActions()),
    ...interactiveGlobalMap({
      dispatch,
      state,
      globalNamespace,
    }),
  }

  return children({ state, interactiveMap })
}

export type TPageRenderProps = {
  name: string
  state: IApplicationState
  interactiveMap: IInteractiveMap
  scripts?: ScriptProps[]
}

const PageRender: React.FC<TPageRenderProps> = ({
  name,
  state: {
    globals: {
      cache: { pages },
    },
  },
  interactiveMap,
  scripts = [],
}) => {
  const page = pages?.[name] || {}
  const { meta = {}, blocks = [], cookiesToSet = '' } = page
  const { title, icon, description } = meta

  const pageTraceId = `NFA_${page.name}_${Date.now()}`

  //TODO: find the root cause of the empty value of ajsAnonymousId and ajsUserId
  cookiesToSet?.split(', ').forEach((cookie) => {
    if (cookie.split(';')[0].split('=')[1]) document.cookie = cookie
  })

  page.cookiesToSet = ''

  return (
    <ErrorBoundary blockName={title}>
      <div className="page-container">
        {title && (
          <Head>
            <title>{title}</title>
            <meta property="og:title" content={title} key="title" />
            {description && (
              <>
                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
              </>
            )}
            {icon && (
              <>
                <link rel="icon" type="image/x-icon" href={icon} />
                <link rel="shortcut icon" type="image/x-icon" href={icon} />
              </>
            )}
          </Head>
        )}
        <>
          {scripts.map((script, idx) => (
            <Script key={`ps-${script.id ?? idx}`} {...script} />
          ))}
        </>

        {blocks.map((block: IBlock, idx: number) => (
          <RenderBlocks
            key={block.name || `pb-${idx}`}
            block={block}
            interactiveMap={interactiveMap}
            pageTraceId={pageTraceId}
          />
        ))}
      </div>
    </ErrorBoundary>
  )
}

Page.Render = PageRender

export default Page
