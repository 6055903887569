/* eslint-disable security/detect-object-injection */
import { Reducer } from 'redux'
import {
  DynamicVSConfiguration,
  ReduxContext,
  SimpleVSConfiguration,
  VSConfiguration,
} from '@bees-web/nfa-types'
import { dependencies } from '../../../config'
import getModule from './getModule'

export type ConfigureReducer = (
  configuration: VSConfiguration,
  redux: ReduxContext
) => Reducer

export type GetReduxContexts = (
  modules: string[],
  configure?: ConfigureReducer
) => Promise<Record<string, ReduxContext>>

export interface AsyncContexts {
  [key: string]: ReduxContext
}

const _modules: AsyncContexts = {}

export const loadDynamicDependencies =
  (configure?: ConfigureReducer) =>
  async (namespace: string): Promise<Record<string, ReduxContext>> => {
    const configuration = dependencies[namespace]
    if (!_modules[namespace] && configuration) {
      try {
        const { redux } = configuration as DynamicVSConfiguration
        let context: ReduxContext = null
        if (redux) {
          context = await getModule(redux)
        } else {
          // Legacy configuration
          const legacy = configuration as SimpleVSConfiguration
          const [interactiveMap, reducer] = await Promise.all([
            getModule(legacy.actions.interactiveMap),
            getModule(legacy.state.reducer),
          ])
          context = {
            actions: {
              ...legacy.actions,
              interactiveMap,
            },
            state: {
              ...legacy.state,
              reducer,
            },
          }
        }
        _modules[namespace] = {
          ...context,
          state: {
            ...context.state,
            reducer: configure
              ? configure(configuration, context)
              : context.state.reducer,
          },
        }
      } catch (error) {
        throw new Error(
          `Error loading ${namespace} Redux configuration: ${error.message}`
        )
      }
    }
    if (!_modules[namespace]) {
      throw new Error(`Reducer not found for namespace: ${namespace}`)
    }
    return { [namespace]: _modules[namespace] }
  }

const getReduxContextMap: GetReduxContexts = async (modules, configure) => {
  const loaded = await Promise.allSettled(
    modules.map(loadDynamicDependencies(configure))
  )
  const contexts = loaded.reduce((acc, curr) => {
    if (curr.status === 'fulfilled') {
      return {
        ...acc,
        ...(curr as PromiseFulfilledResult<Record<string, ReduxContext>>).value,
      }
    }
    return acc
  }, {})

  return contexts
}

export default getReduxContextMap
