/* eslint-disable security/detect-object-injection */
import { ReduxContext } from '@bees-web/nfa-types'
import { Store } from 'redux'
import getReduxContextMap from './getReduxContextMap'
import { configure, loadContextualRedux } from '../../../redux'

export type LoadReduxContext = (
  store: Store,
  dependencies: string[]
) => Promise<Record<string, ReduxContext>>

const loadReduxContext: LoadReduxContext = async (store, dependencies) => {
  const modules = await getReduxContextMap(dependencies, configure)
  loadContextualRedux(store, modules)
  return modules
}

export default loadReduxContext
