import getInteractiveGlobal from '@bees-web/nfa-interactive-global/interactive-map'
import { IApplicationState } from '@bees-web/nfa-types'
import { Dispatch } from 'react'
import { IInteractiveMap } from '../blocks/interfaces'
import { GlobalNamespaces } from '../interfaces'

interface InteractiveGlobalMapArgs {
  dispatch: Dispatch<any>
  state: IApplicationState
  globalNamespace?: string
}

type InteractiveGlobalMap = (args: InteractiveGlobalMapArgs) => IInteractiveMap

export const interactiveGlobalMap: InteractiveGlobalMap = ({
  dispatch,
  state,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  globalNamespace = GlobalNamespaces.DEFAULT,
}) => getInteractiveGlobal(dispatch, state.globals)
