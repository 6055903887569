/* eslint-disable security/detect-object-injection */
import { FC } from 'react'
import getCustomComponent from './getCustomComponent'

export const CUSTOM_BLOCK_TAG = 'RenderComponent'
let _components = null

export const loadComponentPack = async (): Promise<boolean> => {
  if (!_components) {
    try {
      const components = await import('@bees-web/nfa-components-react/lib')
      _components = components
      return true
    } catch (error) {
      console.error(error)
    }
  }
  return true
}

export type GetComponent = (block: { blockType: string; name?: string }) => FC

const toCapital = (str: string): string => {
  const words = str.split('_')
  let result = ''
  words.forEach(
    (word) => (result += word.charAt(0).toUpperCase() + word.slice(1))
  )
  return result
}

const getComponent: GetComponent = (block) => {
  const componentName = toCapital(block.blockType)
  return componentName === 'RenderComponent'
    ? (getCustomComponent(block.name || '') as FC)
    : (_components[componentName] as FC)
}

export default getComponent
