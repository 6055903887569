import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IApplicationState, IBlock } from '@bees-web/nfa-types'
import { renderingOptions } from '../hooks'
import { getHash } from '../utils/getHash'

import {
  replaceVariablesInObject,
  replaceVariablesInString,
} from '../utils/fill-loco-lib'
import { RenderBlocks } from '../blocks'
import { IInteractiveMap } from '../blocks/interfaces'

interface DeferredRenderer {
  url: string
  loadingBlock: IBlock
  parent: IBlock
  interactiveMap: IInteractiveMap
  waitForState?: string[]
  renderIf?: string
  pageTraceId: string
}

interface DetermineLoading {
  blocks: any
  filledOutUrl: string
  method: string
  body?: any
  waitForState?: string[]
  renderIf?: string
}

export const determineLoading = (
  data: string | { url: string; method?: string; body?: object },
  reduxState: IApplicationState,
  waitForState
): DetermineLoading => {
  const {
    deferred: deferredCache,
    meta = {},
    activeCacheKey,
    selectedPocAccount,
    storeId,
  } = reduxState.globals.cache

  const replacer = { reduxState }
  const options = { waitForState }
  let filledOutUrl, method, body
  if (typeof data === 'string') {
    filledOutUrl = replaceVariablesInString(data, replacer, options)
    method = 'GET'
  } else {
    filledOutUrl = replaceVariablesInString(data.url, replacer, options)
    method = data.method
    if (data.body) {
      body = replaceVariablesInObject(data.body, replacer, options)
    }
  }

  // cacheKey has selectedPocAccount and storeId...
  const deferredCacheKey = `${filledOutUrl}&selectedPocAccount=${selectedPocAccount}&storeId=${storeId}${
    body ? `&body=${JSON.stringify(body)}` : ''
  }`

  const hash = getHash(deferredCacheKey)

  // do we have deferredBlocks for this one?
  const { blocks: deferredBlocks } = deferredCache[hash] || {}

  const pageMeta = meta[activeCacheKey] || {}

  const { isValid: isPageValid = false } = pageMeta

  const blocks = isPageValid && deferredBlocks
  /*console.log('qa', {
    deferredBlocks,
    pageMeta,
    activeCacheKey,
    deferredCacheKey,
  })*/
  return { blocks, filledOutUrl, method, body }
}

export const DeferredRenderer: FC<DeferredRenderer> = ({
  url,
  parent,
  loadingBlock,
  interactiveMap,
  waitForState = [],
  renderIf = '',
  pageTraceId = '',
}) => {
  const dispatch = useDispatch()
  const reduxState = useSelector((state: IApplicationState) => state)

  const dependencies = [reduxState]
  const { filledOutUrl, blocks, method, body } = determineLoading(
    url,
    reduxState,
    waitForState
  )

  const props = {
    filledOutUrl,
    waitForState,
    parent,
    method,
    body,
    dispatch,
    renderIf,
    pageTraceId,
  }

  renderingOptions(props, dependencies)

  const block: IBlock = {
    ...parent,
    deferred: null,
    blocks: blocks || [loadingBlock],
  }

  return (
    <RenderBlocks
      block={block}
      interactiveMap={interactiveMap}
      pageTraceId={pageTraceId}
    />
  )
}
