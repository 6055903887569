import React from 'react'
import ErrorStatement from '../error-statement/errorStatement'

interface ErrorBoundaryProps {
  children?: React.ReactNode
  blockName?: string
}

interface ErrorBoundaryState {
  hasError: boolean
  errorInfo: React.ErrorInfo | null
  error: Error | null
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, errorInfo: null, error: null }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ hasError: true, error, errorInfo })
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <ErrorStatement title={this.state.error.name}>
          <p>
            {this.state.error?.message} at: {this.props.blockName}
          </p>
          <p>{this.state.errorInfo?.componentStack}</p>
        </ErrorStatement>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
